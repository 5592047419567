<template>
  <content-loader
    :height="160"
    :width="410"
    :speed="3"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor"
  >
    <rect x="0" y="145" rx="3" ry="3" width="410" height="15"/>
    <rect x="0" y="70" rx="0" ry="0" width="60" height="60"/>
    <rect x="70" y="70" rx="0" ry="0" width="60" height="60"/>
    <rect x="140" y="70" rx="0" ry="0" width="60" height="60"/>
    <rect x="210" y="70" rx="0" ry="0" width="60" height="60"/>
    <rect x="280" y="70" rx="0" ry="0" width="60" height="60"/>
    <rect x="350" y="70" rx="0" ry="0" width="60" height="60"/>
    <circle cx="20" cy="20" r="18"/>
    <rect x="54" y="6" rx="3" ry="3" width="129" height="8"/>
    <rect x="54" y="25" rx="3" ry="3" width="100" height="8"/>
  </content-loader>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'
import Placeholder from './Placeholder'

export default {
  components: {
    ContentLoader
  },
  extends: Placeholder
}
</script>
